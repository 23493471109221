import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet'; // Importing Helmet
import '../styles/Services.css';
import 'animate.css';
import PilatesImg from '../images/AyraP.jpg'
import PilatesImgTwo from '../images/AyraP2.jpg'
import PilatesBanner from '../images/PilatesBanner.png'

const AyraPilates = () => {
    const heroVariants = {
        hidden: {
            opacity: 0,
            y: "-50%",
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.2,
            },
        },
    };

    return (
        <>
            <Helmet>
                <title>Ayra Care | Fisioterapia e Pilates</title>
                <meta name="description" content="Explore Ayra Care's Pilates services designed to enhance physical conditioning, recovery from injuries, and support during pregnancy and postpartum." />
                <meta name="keywords" content="Pilates, Pilates Services, Ayra Care, Physical Therapy, Injury Recovery, Pregnancy Pilates, Pilates para grávidas, pilates recuperação de lesões, Porto, Clinical Pilates" />
                <link rel="canonical" href="https://www.ayracare.com/AyraPilates" />
            </Helmet>
            <div className="AyraPilates" id='AyraPilates'>
                <div className="container">
                    <div className="pilates-banner">
                        <motion.div className="pilates-bannerimg">
                            <img src={PilatesBanner} alt="Pilates Banner" />
                        </motion.div>
                    </div>
                    <div className="services-box">
                        <motion.div 
                            variants={heroVariants}
                            initial='hidden'
                            whileInView='visible'
                            className='pilates-img'
                        >
                            <img src={PilatesImg} alt="Pilates Exercise" />
                            <img src={PilatesImgTwo} alt="Pilates Class" />
                        </motion.div>
                        <motion.div >
                            <h2 className="pilates-header">O que é Pilates?</h2>
                            <p className="pilates-text">
                                O Pilates é um método de exercício físico criado por Joseph Pilates no início do século XX, focado no treinamento do corpo, 
                                especialmente dos músculos centrais, também conhecidos como "core". Essa prática combina movimentos de baixo impacto com controle, 
                                precisão e respiração consciente, promovendo uma integração entre corpo e mente.
                                <br /><br />
                                O método Pilates pode ser realizado no solo, utilizando apenas o peso corporal ou pequenos acessórios, bem como com o auxílio de equipamentos específicos. 
                                Esses equipamentos utilizam molas e polias para fornecer resistência e apoio durante os exercícios.
                                <br /><br />
                                O pilates adentrou a reabilitação física e é amplamente utilizado por fisioterapeutas dentro do processo de reabilitação. 
                                Em Portugal, esta fusão costuma ser chamada de Pilates Clínico. 
                                <br /><br />
                                É importante ressaltar que o pilates é um método profundamente estudado por pesquisadores da área da saúde e vem demonstrando ser altamente eficaz no 
                                tratamento da dor. 
                                <br /><br />
                                Na <b>Ayra</b>, queira você, aumentar o seu condicionamento físico, recuperar de uma lesão, ou cuidar do corpo na gestação ou pós-parto, o pilates é para si.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AyraPilates;
