import React, { useState } from 'react';
import Main from '../components/Main';
import Navbar from '../components/navbar/Navbar';
import { Helmet } from 'react-helmet'; // Import React Helmet for SEO

const HomePage = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const closeNav = () => {
    if (nav) {
      setNav(false); // Close the nav when triggered
    }
  };

  return (
    <div className="pages">
      {/* Add SEO meta tags */}
      <Helmet>
        <title>Ayra Care | Fisioterapia e Pilates</title>
        <meta name="description" content="O nosso compromisso é atender às suas necessidades oferecendo a melhor experiência terapêutica possível, 
          através da mais atualizada evidência científica com uma abordagem pautada no amor e na empatia pelo ser humano em sua integralidade. 
        " />
        <meta name="keywords" content="Ayra Care, Maria Eugênia Ghizellini, Pilates, Pilates Clinico, Saude da Mulher, Physical Therapy, Injury Recovery, Pregnancy Pilates, Pilates para grávidas, recuperação, lesões, Porto, Clinical Pilates" />
        <meta name="author" content="Ayra Care- Maria Eugênia Ghizellini " />
      </Helmet>
      
      {/* Pass closeNav to Navbar for proper functionality */}
      <Navbar nav={nav} handleNav={handleNav} closeNav={closeNav} />
      
      {/* Pass props to Main for smooth interaction between components */}
      <main>
        <Main nav={nav} closeNav={closeNav} handleNav={handleNav} />
      </main>
    </div>
  );
};

export default HomePage;
