import React, { useState, useEffect } from "react";
import "../styles/HeroSection.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { AiOutlineArrowUp } from "react-icons/ai";
import { GiPositionMarker } from "react-icons/gi";
import { animateScroll as scroll } from "react-scroll";
import { motion } from "framer-motion";
import heroImage from "../images/ayra-hero-test.png";
import PopUp from "./PopUp";

const HeroSection = ({ nav, handleNav }) => {
    const [visible, setVisible] = useState(false);
	const [popUpVisible, setPopUpVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        setVisible(scrolled > 300);
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisible);
        return () => window.removeEventListener("scroll", toggleVisible);
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop({ smooth: "linear" });
    };

	const heroVariants = {
		hidden: {
			opacity: 0,
			y: "-50%",
		},
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 1.4,
			},
		},
	};

	const contactVariants = {
		hidden: {
			opacity: 0,
			x: "-50%",
		},
		visible: {
			opacity: 1,
			x: 0,
			transition: {
				duration: 1.4,
			},
		},
	};

	return (
		<div className='hero-section' name='home' id='home'>
			<div className='hero-overlay'></div>
			<motion.div
				aria-label="Menu"
				role="button"
				tabIndex="0"
				onClick={handleNav}
				onKeyPress={(e) => e.key === 'Enter' && handleNav()}
				className='menu-icon'>
				{nav ? <FaTimes /> : <FaBars />}
			</motion.div>
			<motion.div
				variants={heroVariants}
				initial='hidden'
				whileInView='visible'
				className={visible ? "to-top-icon show" : "to-top-icon hide"}
				onClick={scrollToTop}>
				<AiOutlineArrowUp />
			</motion.div>
			<motion.div
				variants={heroVariants}
				initial='hidden'
				whileInView='visible'
				className='hero-content'>
				<img src={heroImage} alt="Hero" className='hero-image' />
			</motion.div>
			<motion.span
				variants={contactVariants}
				initial='hidden'
				whileInView='visible'>
					<button
						onClick={() => setPopUpVisible(true)}
						className='hero-contact'
						aria-label="Onde nos encontrar">
							Onde nos encontrar <GiPositionMarker className='location' />
					</button>
      		</motion.span>
			  <PopUp trigger={popUpVisible} setTrigger={setPopUpVisible} />
		</div>
	);
};

export default HeroSection;
