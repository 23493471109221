import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import SpotImg from '../images/facade.jpg';
import SpotImgTwo from '../images/door.jpg';
import SpotImgThree from '../images/inside.jpg';
import SpotImgFour from '../images/changeRoom.jpg';
import LogoImg from "../images/PilatesBanner.png";
import '../styles/PopUp.css';

const PopUp = ({ trigger, setTrigger }) => {
  const [selectedImage, setSelectedImage] = useState(null); // State to manage selected image

  const handleTouchStart = (e) => {
    console.log('Touch event detected');
  };

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart, { passive: true });

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc); // Set the selected image to display
  };

  const closeImagePopup = () => {
    setSelectedImage(null); // Clear the selected image to close the pop-up
  };

  return trigger ? (
    <div className="popup-background">
      <motion.div
        className="popup-content"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}>
        <button className="close-btn" onClick={() => setTrigger(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="popup-body">
          <div className="section text-section">
            <div className="top-details">
              <div className="img_cover">
                <img src={LogoImg} alt='Main' className='profile-pic-small-popUp' />
              </div>
            </div>
            <div className="popup-welcome">
              <h3>Venha conhecer o Ayra Care</h3>
              <p>Um espaço preparado com todo o cuidado para zelar pelo seu bem estar e proporcionar resultados eficietes</p>
              <p>Esperamos por si!</p>
            </div>
          </div>
          <div className="section location-section">
            <div className="location-info">
              <h2>Onde nos encontrar:</h2>
              <p>Rua Júlio de Brito, nº96</p>
              <p>Código Postal: 4150-049 Porto</p>
            </div>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1502.0264503095434!2d-8.667676!3d41.155197!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246f788344c1ad%3A0x262652e79f60365a!2sR.%20J%C3%BAlio%20de%20Brito%2096%2C%204150-049%20Porto%2C%20Portugal!5e0!3m2!1sen!2sus!4v1729099501022!5m2!1sen!2sus"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Google Maps"
              />
            </div>
          </div>
          <div className="section images-section">
            <h2>O nosso espaço</h2>
            <div className="images-grid">
              <img src={SpotImg} alt="Phisioterapy" onClick={() => handleImageClick(SpotImg)} />
              <img src={SpotImgTwo} alt="Pilates" onClick={() => handleImageClick(SpotImgTwo)} />
              <img src={SpotImgThree} alt="Saude da Mulher" onClick={() => handleImageClick(SpotImgThree)} />
              <img src={SpotImgFour} alt="Education" onClick={() => handleImageClick(SpotImgFour)} />
            </div>
          </div>
        </div>
      </motion.div>

      {/* Conditional rendering for enlarged image pop-up */}
      {selectedImage && (
        <div className='image-popup-background'>
          <motion.div
            className='image-popup-content'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}>
            <button className='close-btn' onClick={closeImagePopup}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
            <img src={selectedImage} alt="Enlarged" className='enlarged-image' />
          </motion.div>
        </div>
      )}
    </div>
  ) : null;
};

export default PopUp;
