import React from 'react';
import "../styles/About.css";
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet'; // Importing Helmet
import ProfileMEGImg from '../images/ProfileMEG.jpg';

const About = () => {
  return (
    <>
      <Helmet>
        <title>Ayra Care | About Us</title>
        <meta name="description" content="Learn about Ayra Care, dedicated to personalized healthcare with a focus on physiotherapy, Pilates, and women's health." />
        <meta name="keywords" content="Ayra Care, About Us, Sobre Ayra, Ayra Pilates, Quem somos, Maria Eugênia Ghizellini, Physiotherapy, Pilates, Women's Health, Personalized Care, Healthcare" />
        <link rel="canonical" href="https://www.ayracare.com/about" />
      </Helmet>
      <div className="about" id='about'>
        <div className="container">
          <motion.div className="heading">
            <p className="heading-sub-text">Ayra Care</p>
            <p className='heading-text'>Quem Somos?</p>
          </motion.div>
          <div className="upper-text-about">
            <motion.div className="about-content">
              <p className="about-text">A Ayra Care surgiu para oferecer cuidados personalizados e individualizados em saúde.</p>
              <br />
              <p className="about-subText">
                O nosso compromisso é atender às suas necessidades oferecendo a melhor experiência terapêutica possível, através da mais atualizada 
                evidência científica com uma abordagem pautada no amor e na empatia pelo ser humano em sua integralidade. As nossas bases são a fisioterapia, 
                o método pilates em aparelhos, e os mais recentes modelos de tratamento da dor crônica. Com um profundo conhecimento em saúde da mulher, 
                estamos preparadas para fornecer cuidados especializados em cada fase da vida.
              </p>
            </motion.div>
          </div>
          <div className="split-about">
            <motion.div className='about-img'>
              <img src={ProfileMEGImg} alt="Maria Eugênia Ghizellini, Founder of Ayra Care" />
            </motion.div> 
            <motion.div className="about-content">
              <p className="about-text2">Maria Eugênia Ghizellini - Fundadora</p>
              <br />
              <p className="about-subText2">
                Fisioterapeuta, inscrita na Ordem dos Fisioterapeutas em Portugal, com formação em Fisioterapia pela Universidade Cidade de São Paulo e graduação 
                em Dança pela Universidade Federal de Viçosa. Desde cedo, a curiosidade sobre o corpo e o movimento guiou sua trajetória profissional.
                <br/><br/>
                Iniciou a carreira na Dança, tanto como bailarina quanto como professora. Com o tempo, devido a lesões decorrentes da prática intensa,  
                encontrou na fisioterapia um caminho para cuidar do seu corpo. Foi amor à primeira vista—uma experiência que além de fascinante, pode proporcionar alívio e cura. 
                Esse encantamento levou a aprofundar seus estudos e seguir a carreira em Fisioterapia, profissão que finalmente permitiu o que sempre quis: cuidar de pessoas.
                <br/><br/>
                Ao longo dessa jornada, seu foco se direcionou para o Pilates e saúde da mulher, com especializações no Brasil, Portugal e Holanda. Como paciente de endometriose e fibromialgia 
                encontra na fisioterapia uma oportunidade de troca valiosa, onde oferece os mesmos cuidados que também necessita. Essa experiência pessoal torna sua prática ainda
                mais gratificante, pois cada atendimento é uma oportunidade de compartilhar e aplicar seu conhecimento na busca pelo bem-estar de suas pacientes.
                Esta busca culminou na criação da Ayra Care.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
