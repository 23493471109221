import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Contact1 from "../images/contact1.jpg";
import Contact2 from "../images/contact2.jpg";
import Contact3 from "../images/contact3.jpg";
import "../styles/Contact.css";
import NavLinks from './navbar/NavLinks';

const Contact = () => {
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (openModal) {
            document.body.classList.add('modal-open');
            console.log("Modal open - no scroll");
        } else {
            document.body.classList.remove('modal-open');
            console.log("Modal closed - scroll enabled");
        }
    }, [openModal]);
    
    const fade = {
        opacity: 1,
        transition:{
            duration: 1.5
        }
    };

    const contactVariants = {
        hidden: {
            opacity: 0,
            y: "-50%",
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 1.4,
            },
        },
    };

    const handleWhatsAppClick = () => {
        setOpenModal(true);
    };

    return (
        <>
            <div className="contact" id='contact'>
                <div className="container">
                    <motion.div className="heading" initial={{opacity: 0}} whileInView={fade} viewport={{ once: true }}>
                        <p className="heading-sub-text">Marque o seu atendimento</p>
                    </motion.div>
                    <div className="image-grid">
                        <img src={Contact2} alt="Contact 2" />
                        <img src={Contact1} alt="Contact 1" />
                        <img src={Contact3} alt="Contact 3" />
                    </div>
                    <div className="contact-box">
                        <motion.div className="left-box" variants={contactVariants} initial='hidden' whileInView='visible'>
                            <div className="contact-heading">
                                <h2>Onde nos encontrar:</h2>
                                <p>Rua Júlio de Brito, numero: 96</p>
                                <p>Código Postal: 4150-049, Pinhais da Foz - Porto</p>
                              </div>
                            <div className="contact-heading">
                                <h3>Mora longe? Não tem problema.</h3>
                            </div>
                            <div className="contact-hello">
                                <p> 
                                    Também oferecemos serviços personalizados na modalidade online (remoto).
                                </p>
                            </div>
                        </motion.div>
                        <motion.div className="right-box">
                            <motion.span
                                onClick={handleWhatsAppClick}
                                variants={contactVariants}
                                initial='hidden'
                                whileInView='visible'
                            >
                                <div className="contact-socials-heading">
                                    <h2>Para saber mais entre em contacto através das nossas redes sociais ou e-mail.</h2>
                                </div>
                                <div className="contact-socials">
                                    <NavLinks></NavLinks>
                                </div>
                            </motion.span>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
