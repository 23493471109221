import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaWhatsapp} from "react-icons/fa"
import {BiEnvelope} from "react-icons/bi"

const NavLinks = ({handleNav}) => {
  return (
    <ul className='nav-links'>
        <li onClick={handleNav} ><Link  to="//www.instagram.com/ayra.carept?igsh=bTF3YnZvZzdtYW45" target='_blank' className='nav-link'><FaInstagram /></Link></li>
        <li onClick={handleNav} ><Link  to="//wa.link/kbsj1o" target='_blank' className='nav-link'><FaWhatsapp /></Link></li>
        <li onClick={handleNav} ><Link  to="//mailto:ayracarept@gmail.com" target='_blank' className='nav-link'><BiEnvelope /></Link></li>
  </ul>
  )
};

export default NavLinks;
