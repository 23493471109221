import React from 'react';
import { motion } from 'framer-motion';
import heroImage from "../images/ayra-hero-test.jpg";
import '../styles/Services.css';

const AyraEduc = () => {
    const heroVariants = {
		hidden: {
			opacity: 0,
			y: "-50%",
		},
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 1.4,
			},
		},
	};
    return (
        <>
            <div className="AyraEduc" id='AyraEduc'>
                <div className="container">
                    <div className="heading">
                        <p className='heading-text-educ'>Em Breve</p>
                    </div>
                    <div className="education-box">
                        <motion.div
                            variants={heroVariants}
                            initial='hidden'
                            whileInView='visible'
                            className='educ-content'>
                            <img src={heroImage} alt="Hero" className='educ-image' />
                        </motion.div>
                        <motion.div >
                            <p className="pilates-text">
                                Temos a missão de disseminar conhecimento em saúde baseado em ciência.
                                <br /><br />
                                Se você tem interesse, fique atento para mais informações em breve. 
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AyraEduc;