import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet'; // Import react-helmet
import About from './About';
import Contact from './Contact';
import HeroSection from './HeroSection';
import AyraPilates from './AyraPilates';
import AyraFisio from './AyraFisio';
import AyraWomenH from './AyraWh';
import AyraEduc from './AyraEduc';

const Main = ({ nav, handleNav, closeNav }) => {
  return (
    <div onClick={closeNav} className="main">
      {/* Add SEO meta tags with react-helmet */}
      <Helmet>
        <title>Ayra Care | Fisioterapia e Pilates</title>
        <meta name="description" content="Ayra Care | Fisioterapia, Pilates e Saude da Mulher - Pinhais da Foz, Porto, Portugal" />
        <meta name="keywords" content="Fisioterapia, Pilates, Foz, pinhais da foz, Foz do Douro, Matosinhos, Ayra Care, Ayra, Pilates Clinico, Saude da Mulher, Pilates Feminino, Pilates para gravidas, Pregnancy Pilates, pavimento pelvico, Maria Eugénia Ghizellini, Physical Therapy, Porto, Wellness, Health, Rehabilitation, Pain Management, Movimento, Bem-Estar, Exercicio, Saúde" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="Ayra Care | Fisioterapia e Pilates" />
        <meta property="og:description" content="Ayra Care | Fisioterapia, Pilates e Saude da Mulher - Pinhais da Foz, Porto, Portugal" />
        <meta property="og:image" content="/path-to-your-social-image.jpg" />
        <meta property="og:url" content="https://ayracare.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ayra Care | Fisioterapia e Pilates" />
        <meta name="twitter:description" content="Ayra Care | Fisioterapia, Pilates e Saude da Mulher - Pinhais da Foz, Porto, Portugal" />
        <meta name="twitter:image" content="/path-to-your-twitter-image.jpg" />
      </Helmet>

      {/* Add framer-motion animation to each section */}
      <motion.div id="hero">
        <HeroSection nav={nav} handleNav={handleNav} />
      </motion.div>

      <motion.div id="about">
        <About />
      </motion.div>

      <motion.div id="ayraPilates">
        <AyraPilates />
      </motion.div>

      <motion.div id="ayraFisio">
        <AyraFisio />
      </motion.div>

      <motion.div id="ayraWomenH">
        <AyraWomenH />
      </motion.div>

      <motion.div id="ayraEduc">
        <AyraEduc />
      </motion.div>

      <motion.div id="contact">
        <Contact />
      </motion.div>
    </div>
  );
};

export default Main;
